.header-search-bar .ant-input:not(:first-child) {
  padding-left: 60px;
  font-size: 18px;
  background: #f0f2f5;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0px;
}

.ant-list-vertical .ant-list-item-action {
  margin-top: 0;
}

.hide-element-on-keyboard {
  display: normal;
}

.ant-card {
  line-height: 1.25;
}

g > text {
  text-anchor: left !important;
}

.ant-card-head-title {
  padding: 10px 0;
  padding-bottom: 6px;
}

.ant-card-meta-title {
  overflow: visible;
}

@import url('./react_dates.css');
@media screen and (max-height: 400px) {
  .hide-element-on-keyboard {
    display: none;
  }
}

.hide-logo-on-keyboard {
  display: normal;
}

@media screen and (max-height: 300px) {
  .hide-logo-on-keyboard {
    display: none;
  }
}
